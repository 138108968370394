.tablehead {
  background: #01ac8a;
  color: white;
}

.input-fieldss {
  justify-content: center;
  width: 50%;
  padding: 5px;
  outline: none;
}

.btnss {
  background: linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%);
  color: white;
  margin: 15px;
  height: 50px;
  width: 95px;
  border-radius: 20px;
  opacity: 0.9;
}


/* Legend CSS */
legend.scheduler-border {
  width:inherit; /* Or auto */
  padding:0 10px; /* To give a bit of padding on the left and right */
  border-bottom:none;
}

/*Progress CSS */ 
#app {
  margin-top: 40px;
}

#progressInput {
  margin: 20px auto;
  width: 30%;
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #ddd;
}

.circle-progress {
  stroke: #01ac8a;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 3em;
  font-weight: bold;
  fill: #01425e;
}
