* {
	box-sizing: border-box;
}

/* Style the input container */
.input-containerss {
	display: flex;
	width: 100%;
	margin-bottom: 15px;
}

/* Style the form icons */
.icons {
	padding: 10px;
	background: linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%);
	color: white;
	min-width: 50px;
	text-align: center;
}

/* Style the input fields */
.input-fields {
	width: 100%;
	padding: 5px;
	outline: none;
}

.input-fields:focus {
	border: 2px solid #01425e;
}

/* Set a style for the submit button */
.btns {
	font-size: 17px;
	background: #01ac8a;
	color: white;
	padding: 10px;
	border: none;
	padding: 15px;
	cursor: pointer;
	float: right;
	opacity: 0.9;
	border-radius: 10px;
}
.btnAdd {
	background: linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%);
	color: white;
	padding: 8px 20px;
	border: none;
	cursor: pointer;
	width: 100%;
	opacity: 0.9;
	border-radius: 5px;
}

.btns:hover {
	opacity: 1;
}

.head {
	border-radius: 20px;
	background: linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%);
}

hr.style-head {
	padding: 0;
	border: none;

	border-top: medium double #01ac8a;
	color: #333;
	text-align: center;
}
hr.style-head:after {
	display: inline-block;
	position: relative;
	top: -0.7em;
	font-size: 1.5em;
	padding: 0 0.25em;
	background: white;
}

hr.style-four {
	padding: 0;
	border: none;
	border-top: medium double #01ac8a;
	color: #333;
	text-align: center;
}
hr.style-four:after {
	content: 'Student Admission Form';
	display: inline-block;
	position: relative;
	top: -0.7em;
	font-size: 1.5em;
	padding: 0 0.25em;
	background: white;
}

/* Text between <hr> tag */

hr.style-five {
	padding: 0;
	border: none;
	border-top: medium double #01ac8a;
	color: #333;
	text-align: center;
}
hr.style-five:after {
	content: 'Staff Admission Form';
	display: inline-block;
	position: relative;
	top: -0.7em;
	font-size: 1.5em;
	padding: 0 0.25em;
	background: white;
}

hr.style-six {
	padding: 0;
	border: none;
	border-top: medium double #01ac8a;
	color: #333;
	text-align: center;
}
hr.style-six:after {
	content: 'Qualification';
	display: inline-block;
	position: relative;
	top: -0.7em;
	font-size: 1.5em;
	padding: 0 0.25em;
	background: white;
}

hr.style-seven {
	padding: 0;
	border: none;
	border-top: medium double #01ac8a;
	color: #333;
	text-align: center;
}
hr.style-seven:after {
	content: 'Experiences';
	display: inline-block;
	position: relative;
	top: -0.7em;
	font-size: 1.5em;
	padding: 0 0.25em;
	background: white;
}

hr.style-eight {
	padding: 0;
	border: none;
	border-top: medium double #01ac8a;
	color: #333;
	text-align: center;
}
hr.style-eight:after {
	content: 'Skills';
	display: inline-block;
	position: relative;
	top: -0.7em;
	font-size: 1.5em;
	padding: 0 0.25em;
	background: white;
}

hr.style-nine {
	padding: 0;
	border: none;
	border-top: medium double #01ac8a;
	color: #333;
	text-align: center;
}
hr.style-nine:after {
	content: 'Siblings';
	display: inline-block;
	position: relative;
	top: -0.7em;
	font-size: 1.5em;
	padding: 0 0.25em;
	background: white;
}

div.spacing {
	margin-top: 20;
}
