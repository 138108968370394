.dropdown {
}

.buttonEdit {
}
.hover-button .hover-button--on,
.hover-button:hover .hover-button--off {
  display: none;
}

.hover-button:hover .hover-button--on {
  display: inline;
}
.cardedit {
  margin: 50px;
}

.mainBackImg {
  background-image: url("../../../Assets/DoratPictures/back-2.jpg");
  height: 100vh;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 0%;
}
/* .nav {
  background: url("../../../Assets/images/backimg.jpeg");
  height: 659px;
  margin: 0 auto;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 100%;
  position: relative;
  bottom: 59px;
} */

.change {
  width: 500px;
  height: 240px;
  margin: 5px;
}
.b {
  border-spacing: 200px;
  display: inline-block;
  width: 50px;
}

.logo {
  width: 120px;
  height: 100px;
}

.set {
  border-style: solid;
  height: 200px;
  width: 200px;
}
