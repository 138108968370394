.divclassimg {
  overflow: hidden;
}
.divclassimg img {
  width: 100%;
  height: 200px;
}
.rowCheckFinance {
  height: 45px;
  margin: 0px;
}
.colCheckFinance {
  border: 1px solid #0a4f5e;
  padding: 6px;
  color: #0a4f5e;
}
@media print {
  .print h2 {
    -webkit-print-color-adjust: exact;
  }
  .print h1 {
    -webkit-print-color-adjust: exact;
  }
}

.borderless {display:inline;margin:10px;padding:5px;border:none;border-bottom:1px solid #000;outline:none;}
