.backgroundImg {
  height: 570px;
  background-image: url("../../Assets/images/backimg.jpeg");
  margin: 0 auto;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 100%;
  position: relative;
  bottom: 59px;
}

.contestLeaders {
  /* overflow:hidden; */
  /* margin-bottom:30px; */
  /* position: relative; */
}
.form-group:focus {
  outline: 0px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none;
}
.buttonAppear {
  background: linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%);
  border-color: white;
}
.DemoCss {
  border: 1px solid black;
}
.mainDiv {
  background: white;
  border-radius: 8px;
}
.textFeildClass {
  border: none;
}

.upper_class {
  width: "100%";
  /* height: 170px; */
  border-radius: 8px;
  /* overflow: hidden; */
}
.image_Text {
  color: white;
  position: relative;
  display: inline;
  top: 10px;
  font-size: 40px;
  font-weight: bold;
}
.imageClass {
  box-shadow: 5px 5px 5px gray;
  text-align: center;
  display: block;
  border-radius: 5px;
  margin: 0 auto;
  width: 90%;
  min-width: "100%";
  min-height: 70px;
  position: relative;
  bottom: 30px;
  background: #17a2b8;
  background-repeat: no-repeat;
  /* opacity: 0.8; */
  background-position: center;
  background-size: cover;
}
