.rowCheck {
  height: 45px;
  margin: 0px;
}
.colCheck {
  border: 1px solid #17a2b8;
  padding: 6px;
  color: #20344c;
}

/* Finance Button Income */
.buttonHover2 {
  border: none;
  height: 120%;
  border-radius: 10%;
  background: none;
  font-size: 20px;
  background-color: #0a4f5e;
  color: #dbf8c8;
}

.buttonHover2:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  color: black;
}

/* End Finance Button */
.buttonHoverFillColor {
  border: none;
  /* width: 80px; */
  height: 40px;
  border-radius: 10%;
  background: none;
  background-color: #dbf8c8;
  font-size: 20px;
}

.buttonHoverFillColor:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  background-color: #01ac8a;
}
.buttonHover {
  border: none;
  border-radius: 10%;
  background: none;
  font-size: 20px;
}
.buttonHoverFill {
  border: none;
  width: 80px;
  height: 40px;
  border-radius: 20%;
  background: none;
  background-color: #fff;
  font-size: 20px;
}

.buttonHoverFill:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  background-color: #01ac8a;
}

.buttonHover:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
}

/* For Skills */
.colCheckb {
  border: 2px solid #0a4f5e;
  color: #0a4f5e;
}
.verticalTableHeader {
  text-align: center;
  white-space: nowrap;
  transform-origin: 50% 50%;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.verticalTableHeader:before {
  content: "";
  padding-top: 110%; /* takes width as reference, + 10% for faking some extra padding */
  display: inline-block;
  vertical-align: middle;
}
.verticalTableHeaderA {
  text-align: center;
  white-space: nowrap;
  transform-origin: 65% 65%;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.verticalTableHeaderA:before {
  content: "";
  padding-top: 110%; /* takes width as reference, + 10% for faking some extra padding */
  display: inline-block;
  vertical-align: middle;
}
.verticalTableHeaderE {
  text-align: center;
  white-space: nowrap;
  transform-origin: 70% 70%;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.verticalTableHeaderE:before {
  content: "";
  padding-top: 110%; /* takes width as reference, + 10% for faking some extra padding */
  display: inline-block;
  vertical-align: middle;
}
.verticalTableHeaderP {
  text-align: center;
  white-space: nowrap;
  transform-origin: 60% 60%;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.verticalTableHeaderP:before {
  content: "";
  padding-top: 110%; /* takes width as reference, + 10% for faking some extra padding */
  display: inline-block;
  vertical-align: middle;
}
.verticalTableHeaderT {
  text-align: center;
  white-space: nowrap;
  transform-origin: 65% 65%;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.verticalTableHeaderT:before {
  content: "";
  padding-top: 110%; /* takes width as reference, + 10% for faking some extra padding */
  display: inline-block;
  vertical-align: middle;
}

.imgcheck {
  overflow: hidden;
  width: 1000px;
  height: 200px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.divclass {
  overflow: hidden;
}
.divclass img {
  width: 100%;
  height: 200px;
}

.wow {
  width: 80%;
  margin: 0 auto;
}

@media print {
  .print h2 {
    -webkit-print-color-adjust: exact;
  }
  .print h4 {
    -webkit-print-color-adjust: exact;
  }
  .print h1 {
    -webkit-print-color-adjust: exact;
  }
  .hojaa {
    page-break-before: always;
  }
  #footer {
    position: fixed;
    bottom: 0px;
    right: 16px;
  }
  /* .print thead {
		-webkit-print-color-adjust: exact;
	} */
}
.headCol {
  background-color: #01ac8a;
  color: white;
  -webkit-print-color-adjust: exact;
}
.boldText label {
  font-weight: bold;
}
.fontBoldColor {
  margin-left: 5px;
  font-weight: bold;
  color: #0a4f5e;
  font-size: 17px;
}
.tableCenter {
  text-align: center;
  border: 1px solid green;
  padding: 30px;
}
.tableCenter table {
  border: 1px solid red;
  /* You can also uncomment this and remove the align="center" attribute    
		margin: 0 auto;
	*/
}
.hiding {
  display: none;
}
@media print {
  .tablecheck {
    background-color: #01ac8a !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .hiding {
    display: block;
    position: relative;
    min-height: 150px;
  }
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
@media print {
  tr.vendorListHeading {
    background-color: #01ac8a !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .vendorListHeading td {
    color: #01ac8a !important;
    -webkit-print-color-adjust: exact;
  }
}
@media print {
  #tbl1 {
    background-color: #01ac8a;
  }
}
