.file-upload {
	display: block;
	text-align: center;
	font-family: Helvetica, Arial, sans-serif;
	font-size: 12px;
	width: 100%;
}
.file-upload .file-select {
	display: block;
	border: 2px solid #dce4ec;
	color: #34495e;
	cursor: pointer;
	height: 40px;
	line-height: 40px;
	text-align: left;
	background: #ffffff;
	overflow: hidden;
	position: relative;
}
.file-upload .file-select .file-select-button {
	background: #dce4ec;
	padding: 0 10px;
	display: inline-block;
	height: 40px;
	line-height: 40px;
}
.file-upload .file-select .file-select-name {
	line-height: 40px;
	display: inline-block;
	padding: 0 10px;
}
.file-upload .file-select:hover {
	border-color: #34495e;
	transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
}
.file-upload .file-select:hover .file-select-button {
	background: #34495e;
	color: #ffffff;
	transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
}
.file-upload.active .file-select {
	border-color: #3fa46a;
	transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
}
.file-upload.active .file-select .file-select-button {
	background: #3fa46a;
	color: #ffffff;
	transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
}
.file-upload .file-select input[type='file'] {
	z-index: 100;
	cursor: pointer;
	position: absolute;
	height: 100%;
	width: 50%;
	top: 0;
	left: 0;
	opacity: 0;
	filter: alpha(opacity=0);
}
.file-upload .file-select.file-select-disabled {
	opacity: 0.65;
}
.file-upload .file-select.file-select-disabled:hover {
	cursor: default;
	display: block;
	border: 2px solid #dce4ec;
	color: #34495e;
	cursor: pointer;
	height: 40px;
	line-height: 40px;
	margin-top: 5px;
	text-align: left;
	background: #ffffff;
	overflow: hidden;
	position: relative;
}
.file-upload .file-select.file-select-disabled:hover .file-select-button {
	background: #dce4ec;
	color: #666666;
	padding: 0 10px;
	display: inline-block;
	height: 40px;
	line-height: 40px;
}
.file-upload .file-select.file-select-disabled .file-select-name {
	line-height: 40px;
	display: inline-block;
	padding: 0 10px;
}

/* New File Upload Css  */

.file-upload2 {
	display: block;
	text-align: center;
	font-family: Helvetica, Arial, sans-serif;
	font-size: 12px;
	width: 100%;
}
.file-upload2 .file-select2 {
	display: block;
	border: 2px solid #dce4ec;
	color: #34495e;
	cursor: pointer;
	height: 40px;
	line-height: 40px;
	text-align: left;
	background: #ffffff;
	overflow: hidden;
	position: relative;
}
.file-upload2 .file-select2 .file-select-button2 {
	background: #dce4ec;
	padding: 0 10px;
	display: inline-block;
	height: 40px;
	line-height: 40px;
}
.file-upload2 .file-select2 .file-select-name2 {
	line-height: 40px;
	display: inline-block;
	padding: 0 10px;
}
.file-upload2 .file-select2:hover {
	border-color: #34495e;
	transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
}
.file-upload2 .file-select2:hover .file-select-button2 {
	background: #34495e;
	color: #ffffff;
	transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
}
.file-upload2.active .file-select2 {
	border-color: #3fa46a;
	transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
}
.file-upload2.active .file-select2 .file-select-button2 {
	background: #3fa46a;
	color: #ffffff;
	transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
}
.file-upload2 .file-select2 input[type='file'] {
	z-index: 100;
	cursor: pointer;
	position: absolute;
	height: 100%;
	width: 50%;
	top: 0;
	left: 0;
	opacity: 0;
	filter: alpha(opacity=0);
}
.file-upload2 .file-select2.file-select2-disabled {
	opacity: 0.65;
}
.file-upload2 .file-select2.file-select2-disabled:hover {
	cursor: default;
	display: block;
	border: 2px solid #dce4ec;
	color: #34495e;
	cursor: pointer;
	height: 40px;
	line-height: 40px;
	margin-top: 5px;
	text-align: left;
	background: #ffffff;
	overflow: hidden;
	position: relative;
}
.file-upload2 .file-select2.file-select2-disabled:hover .file-select-button2 {
	background: #dce4ec;
	color: #666666;
	padding: 0 10px;
	display: inline-block;
	height: 40px;
	line-height: 40px;
}
.file-upload2 .file-select2.file-select2-disabled .file-select-name2 {
	line-height: 40px;
	display: inline-block;
	padding: 0 10px;
}
