.modal_custom_header {
  border-radius: 30px;
  background: linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%);
}
.bordersSpace {
  border: solid;
  border-color: #01ac8a;
  border-width: 1px;
}
.modal_content_custom {
  border-radius: 20px !important;
}
.buttonAppear {
  background: linear-gradient(to bottom left, #fcd810 5%, #f47856 87%);
  border-color: white;
}
.text-primary {
  color: #01ac8a !important;
}
.td {
  padding: 4px;
}

.showCardColorVisitor {
  background-color: #fcd810;
}

@media print {
  .showCardColorVisitor {
    -webkit-print-color-adjust: exact; 
  }
}

/* Shadow CSS */
.shadowForBox {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.vl {
  border-left: 1px solid gray;
  height: 130px;
}
/* Table Scroll CSS */

.my-custom-scrollbar {
  position: relative;
  height: 320px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}

/* Legend CSS */

legend.scheduler-border {
  width: inherit; /* Or auto */
  padding: 0 10px; /* To give a bit of padding on the left and right */
  border-bottom: none;
}

/* Print CSS */

@media print {
  tr.vendorListHeading {
    background-color: #01ac8a !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .vendorListHeading th {
    color: #01ac8a !important;
  }
}
@media print {
  #tbl1 {
    background-color: #01ac8a;
  }
}
