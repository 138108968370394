.modal_custom_header {
    /* border-radius: 30px; */
    background: linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%);
}
.modal_content_custom {
    border-radius: 20px !important;
}
.buttonAppear {
    background:linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%);
    border-color:white;
}
.text-primary {
    color:#01AC8A !important;
}