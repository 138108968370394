.jumbotron {
  background-image: url('./../Assets/images/notfound.jpeg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
  height: auto;
 }
 .responsive {
  width: 100%;
  height: auto;
}