.FormHeadingStyle {
  text-align: center;
  color: white;
  background-color: #17a2b8;
}
.rowStaffCheck {
  height: 45px;
  margin: 0px;
}
.colStaffCheck {
  border: 1px solid #17a2b8;
  padding: 6px;
  color: #20344c;
}

.imgcheck {
  overflow: hidden;
  width: 1000px;
  height: 200px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.divclass {
  overflow: hidden;
}
.divclass img {
  width: 100%;
  height: 200px;
}

.wow {
  width: 80%;
  margin: 0 auto;
}

@media print {
  .print h2 {
    -webkit-print-color-adjust: exact;
  }
  .print h1 {
    -webkit-print-color-adjust: exact;
  }
  .hojaa {
    -webkit-print-color-adjust: exact;
    page-break-before: always;
  }
  #footer {
    position: fixed;
    bottom: 0px;
    right: 16px;
  }
}
