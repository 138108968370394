:root {
  --main-primary-color: #01ac8a;
  --main-primary-color-two: #bde9e1;
  --main-second-primary-color: #93d8dd;
  --main-second-primary-color-two: #e3f5f6;
  --main-third-primary-color: #a75a8d;
  --main-third-primary-color-two: #e2c9da;
  --main-fourth-primary-color: #f2805c;
  --main-fourth-primary-color-two: #facabd;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}

body {
  font-family: "DM Sans", sans-serif;
}

.borderRemove {
  border: none;
}
.flagClass {
  padding: 47px 29px 25px !important;
  width: 49% !important;
}
.transparent {
  background: transparent;
}
#t-table table,
th,
td {
  border-color: black;
}
.doubleCircularDiv {
  /* width: 40px; */
  /* height: 50vw; */
  background: linear-gradient(
    -45deg,
    var(--main-primary-color),
    var(--main-primary-color) 100%,
    white 49%,
    white 51%
  );
  border-radius: 50%;
}
.doubleCircularDiv4 {
  /* width: 40px; */
  /* height: 50vw; */
  background: linear-gradient(
    -45deg,
    var(--main-fourth-primary-color),
    var(--main-fourth-primary-color) 100%,
    white 49%,
    white 51%
  );
  border-radius: 50%;
}
.doubleCircularDiv3 {
  /* width: 40px; */
  /* height: 50vw; */
  background: linear-gradient(
    -45deg,
    var(--main-third-primary-color),
    var(--main-third-primary-color) 100%,
    white 49%,
    white 51%
  );
  border-radius: 50%;
}
.doubleCircularDiv2 {
  /* width: 40px; */
  /* height: 50vw; */
  background: linear-gradient(
    -45deg,
    var(--main-second-primary-color),
    var(--main-second-primary-color) 100%,
    white 49%,
    white 51%
  );
  border-radius: 50%;
}
#logimg {
  height: 70px;
}
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: #d3d3d3 radial-gradient(circle, transparent 1%, #d3d3d3 1%)
    center/15000%;
}
.ripple:active {
  background-color: #d3d3d3;
  background-size: 100%;
  transition: background 0s;
}

.caAssignMarksHead {
  background-color: #0a4f5e;
  color: #fff;
}
.caAssignMarks {
  width: 80%;
  margin-left: 0px;
  text-align: center;
}
@media print {
  .lineGraph {
    width: 102%;
  }
  .displayElemet {
    display: none;
  }
  .newPrintCss {
    background-color: #0a4f5e !important;
    color: #fff !important;
    -webkit-print-color-adjust: exact;
  }
  .print-table-head {
    background-color: #01ac8a !important;
    color: #01ac8a !important;
    -webkit-print-color-adjust: exact;
  }
  .print-td-border {
    border: 1px solid #01ac8a !important;
    -webkit-print-color-adjust: exact;
  }
}
.tablecheck {
  background-color: #01ac8a !important;
  color: #fff;
  -webkit-print-color-adjust: exact;
}
.filter-section {
  text-align: left;
  border: 2px solid #01ac8a;
  padding: 10px;
  border-radius: 7px;
  margin: 10px;
}
.custom-placement {
  position: relative;
  bottom: 20px;
  left: 5px;
}
.custom-badge-filter {
  background-color: #0a4f5e;
  padding: 5px;
  border-radius: 3px;
  font-size: 20px;
  font-weight: normal;
  color: white;
  letter-spacing: 13px;
}
.customBadge {
  background-color: #0a4f5e;
  padding: 3px;
  border-radius: 25px;
  font-weight: normal;
  color: white;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  /* background-color: #01ac8a;
  border-color: #01ac8a; */
}
.myhoverClass:hover {
  box-shadow: 5px 5px 5px gray;
  margin-top: 5px;
  border-radius: 5px;
  transition: 0.2s;
}
#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #3fa46a; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
	However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* grade box for student disicipline report */

#gradeBox {
  border: 1px solid black;
}

/* REPORT HEADING */

.reportHead {
  font-size: 35px;
  color: #0a4f5e;
  text-decoration: underline;
  text-align: center;
  margin-top: 10px;
}
/* end of grade box  */
.dispFlexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
.div-table {
  display: table;
  width: auto;
  background-color: #eee;
  border: 1px solid #666666;
  border-spacing: 5px; /* cellspacing:poor IE support for  this */
}
.div-table-row {
  display: table-row;
  width: auto;
  clear: both;
}
.div-table-col {
  float: left; /* fix for  buggy browsers */
  display: table-column;
  width: 200px;
  background-color: #ccc;
}

.btnStandard {
  border: none;
  padding: 10px;
  color: #fff;
  border-radius: 5px;
}

/* List View Table Row Colors */
.table-row {
  background-color: #01ac8a;
  color: #fff;
}

/* button clas */
#myDiv {
  text-align: center;
}

#myUpperSpan {
  overflow: auto;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}
#mySpan1 {
  overflow: auto;
  writing-mode: vertical-lr;
  transform: rotate(270deg);
}
#mySpan {
  overflow: auto;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}
.page-footer {
  display: none;
}
@media print {
  .page-footer {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    font-size: large;
    border-top: 2px solid black; /* for demo */
    background: #c3f7ca;
  }
}
section {
  padding: 0px;
}

.disabled-link {
  pointer-events: none;
}
.vericaltext {
  font-weight: 900;
  width: 1px;
  word-wrap: break-word;
  /* font-family: monospace; this is just for good looks */
  white-space: pre-wrap;
  color: #fff;
}
.searchBtnClass {
  border-radius: 12%;
  border: none;
  background-color: #01ac8a;
  color: #fff;
}
.generalHead {
  text-align: center;
  /* background: #01ac8a; */
  border-radius: 10px;
  padding: 8px;
  width: 80%;
  color: #fff;
  margin: 0 auto;
  letter-spacing: 11px;
}
@media print {
  .generalHead {
    color: #000 !important;
    background: transparent;
    /* -webkit-print-color-adjust: exact; */
  }
}
.reportText {
  font-size: 25px;
  color: #0a4f5e;
  text-decoration: underline;

  margin-top: 10px;
}
.dashboardHead {
  background: #01ac8a;
  padding: 8px;
  width: 40%;
  color: #fff;
  margin: 0 auto;
  letter-spacing: 11px;
}
.customButton {
  border: none;
  background-color: #3fa46a;
  color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: none;
}
/* end of button class */
@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
.note {
  display: flex;
  justify-content: space-evenly;
  margin: 30px;

  flex-wrap: wrap;
}
.name {
  font-size: 15px;
  font-weight: bold;
}
.code {
  font-size: 15px;

  font-weight: bold;
}

/* end of bar */
.btn-smart {
  background: #01ac8a;
  color: #eee;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid white;
}
.btn-smart-one {
  background: #01ac8a;
  color: #eee;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid white;
}
.btn-smart-one-small {
  background: #01ac8a;
  color: #eee;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid white;
}
.disTime {
  font-size: 15px;
}
/* select styling */
.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.select-selected {
  background-color: DodgerBlue;
}
.generalHead {
  text-align: center;
  background: #01ac8a;
  border-radius: 10px;
  padding: 8px;
  width: 100%;
  color: #fff;
  margin: 0 auto;
  letter-spacing: 11px;
}
/* style for file input */
.filenew-upload {
  display: contents;
}
/* end style for file input */
/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}
/* modal full */
.modal-full {
  min-width: 100%;
  margin: 0;
}
.modal-full .modal-content {
  min-height: 100vh;
}

/* modal end */
/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}
.wraphai {
  margin: 0 auto;
  width: 80%;
  height: 100px;

  /* margin-left: 100px; */
  -webkit-perspective: 100px; /* Chrome, Safari, Opera  */
  perspective: 100px;
  /* margin-right: 100px; */
}

.testhai {
  color: #fff;
  font-size: 30px;
  margin: "0 auto";
  height: 50px;
  width: 100%;
  background: #0a4f5e;
  -webkit-transform: rotateX(20deg);
  /* Chrome, Safari, Opera  */
  transform: rotateX(20deg);
}
.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

/* end of select stying */
.textField {
  border-radius: 5px;
}
.dateTimePicker {
  font-size: 20px;
  width: "40%";
}

.ttBtnStyling {
  background-color: #01ac8a;
  color: #fff;
  border-color: "#01AC8A";
  width: 15%;
  padding: 10px;
  margin-top: 10px;
  align-self: flex-end;
  border-radius: 5px;
}
.specialTPicker {
  font-size: 20px;
  width: "80%";
}
.mainBackColor {
  background: linear-gradient(
    left top,
    #ffffff 0%,
    #e1e1e1 25%,
    #f1f1f1 49%,
    #e1e1e1 77%,
    #f6f6f6 100%
  );
}
.chooseBTN {
  padding: 5px 10px;
  background: linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%);
  /* border: 1px solid #01ac8a; */
  position: relative;
  color: #fff;
  /* border-radius: 2px; */
  text-align: center;
  float: left;
  cursor: pointer;
}
.hide_file {
  position: absolute;
  z-index: 1000;
  opacity: 0;
  cursor: pointer;
  right: 0;
  top: 0;
  height: 100%;
  font-size: 24px;
  width: 100%;
  /* border-radius: 10px; */
}
/* Radio styling */
.containerRadio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.containerRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}
.containerRadio:hover input ~ .checkmark {
  background-color: #ccc;
}
.containerRadio input:checked ~ .checkmark {
  background-color: #000;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.containerRadio input:checked ~ .checkmark:after {
  display: block;
}
.containerRadio .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
/* end of radio styling */
/* checkbox Stylig */
.containerCheck {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.containerCheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkBoxmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}
/* On mouse-over, add a grey background color */
.containerCheck:hover input ~ .checkBoxmark {
  background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.containerCheck input:checked ~ .checkBoxmark {
  background-color: #000;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkBoxmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.containerCheck input:checked ~ .checkBoxmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.containerCheck .checkBoxmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.textFeildInput {
  width: 100%;
  padding: 0px 10px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
/* end of checkbox styling */
.file-upload {
  display: block;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
}
.file-upload .file-select {
  display: block;
  /* border: 2px solid #dce4ec; */
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
}
.file-upload .file-select .file-select-button {
  background: #dce4ec;
  padding: 0 10px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
}
.file-upload .file-select .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}

.file-upload .file-select:hover {
  /* border-color: #000; */
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.file-upload .file-select:hover .file-select-button {
  background: #000;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.file-upload.active .file-select {
  /* border-color: #000; */
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.file-upload.active .file-select .file-select-button {
  background: #000;
  color: #ffffff;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.file-upload .file-select input[type="file"] {
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  filter: alpha(opacity=0);
}
.file-upload .file-select.file-select-disabled {
  opacity: 0.65;
}
.file-upload .file-select.file-select-disabled:hover {
  cursor: default;
  display: block; /* border: 2px solid #dce4ec; */
  color: #34495e;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  margin-top: 5px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
}
.file-upload .file-select.file-select-disabled:hover .file-select-button {
  background: #dce4ec;
  color: #666666;
  padding: 0 10px;
  display: inline-block;

  height: 40px;
  line-height: 40px;
}
.file-upload .file-select.file-select-disabled:hover .file-select-name {
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
}
.editBtn {
  border-radius: 5px;
  background: #01425e;
  color: #ffffff;
}
.editBtn:hover {
  background: linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%);
  border-radius: 0px;
}
/* border:'1px solid black',height:40,width:250,alignItems:'flex-start' */
.stepNotifier {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-right: none;
  /* border: 1px solid white; */
  position: fixed;
  height: 40px;
  width: 130px;
  /* background: linear-gradient(to bottom left, #01425e 5%, #01ac8a 87%); */
  align-items: flex-start;
  color: white;
  font-size: 25px;
  box-shadow: 5px 5px 5px #01425e;
  text-align: center;
}
#btnAppNot {
  border-color: #eee;
  padding: 10;
}
.dateStyle > .react-date-picker__wrapper {
  display: table;
  border: none;
}
