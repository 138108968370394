.contestLeaders
{
    overflow:hidden;
    margin-bottom:30px;
    position: relative;
}
.checkCss{
  border:1x solid black;
}
.marginLess {
  margin-bottom: -30px;
}