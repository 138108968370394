/* .editorContainer {
} */

.editors {
	border: 1px #01425e solid;
	padding: 1em;
	margin: 1em;
	height: auto;
	font-family: 'Open Sans';
	font-size: 120%;
}
.btnSpace {
	margin: 20px;
}
